import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaWind, FaSnowflake, FaTools, FaWrench } from 'react-icons/fa';
import config from "../config";

const ServiceCard = ({ icon, title, description, image }) => {

    return (
        <div className={`bg-gradient-to-br from-black via-gray-900 to-black text-white rounded-lg shadow-md overflow-hidden`}>
            <div className="p-6 flex flex-col items-center text-center">
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="lg:text-2xl text-xl font-semibold mb-2">{title}</h3>
      <p className="lg:text-xl">{description}</p>
    </div>
        </div>
    );
};

const Services = () => {
  const { t } = useTranslation();
  const primaryColor = config.env.primaryColor || "#ED6D2D";
  const ASSETS_DIR = config.env.img_dir || "assets";

  const path = require(`../${ASSETS_DIR}/services.jpg`);

  const services = [
    {
      icon: <FaWind className="text-pink-500" />,
      title: t('Services.climatisation'),
      description: t('Services.climatisationDesc'),
      image: '/images/climatisation.jpg'
    },
    {
      icon: <FaSnowflake className="text-cyan-500" />,
      title: t('Services.refrigeration'),
      description: t('Services.refrigerationDesc'),
      image: '/images/refrigeration.jpg'
    },
    {
      icon: <FaTools className="text-green-500" />,
      title: t('Services.entretien'),
      description: t('Services.entretienDesc'),
      image: '/images/entretien.jpg'
    },
    {
      icon: <FaWrench className="text-blue-500" />,
      title: t('Services.depannage'),
      description: t('Services.depannageDesc'),
      image: '/images/depannage.jpg'
    }
  ];

  const gradientStyle = {
    background: `linear-gradient(to top right, #000000 0%, #000000 50%, ${primaryColor} 100%)`,
    boxShadow: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`
  };

  return (
    
    <div className="w-full py-16 text-white px-4" style={gradientStyle}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2 font-extrabold text-center mb-12">
          {t('Services.title')}
        </h1>
        
        {/* Introduction section */}
        <div className="flex flex-col md:flex-row items-center mb-16">
          <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0">
            <h3 className="md:text-4xl sm:text-3xl text-2xl font-semibold mb-4">{t('Services.introTitle')}</h3>
            <p className="lg:text-2xl ">{t('Services.introText')}</p>
          </div>
          <div className="md:w-1/2">
            <img 
              src={path}
              alt="Our Services" 
              className="rounded-lg shadow-md w-full h-auto object-cover"
            />
          </div>
        </div>

        {/* Service cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
