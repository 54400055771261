import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import config from "../config";

const ThankYouPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const primaryColor = config.env.primaryColor || "#ED6D2D";
  const handleBackToHome = () => {
    navigate("/"); // Navigate to the home page
  };

  return (
    <div className="w-full py-16 px-4">
      <div className="max-w-[1240px] sm:mx-auto sm:w-full sm:max-w-md text-center">
        <div>
          <h2 className="mt-8 text-3xl font-bold leading-9 tracking-tight text-white">
            {t("ThankYouPage.title")}
          </h2>
          <p className="mt-4 text-lg leading-7 text-gray-400">
            {t("ThankYouPage.message")}
          </p>
          <p className="mt-2 text-sm leading-6 text-gray-400">
            {t("ThankYouPage.sub_message")}
          </p>
        </div>
        <div className="mt-8">
          <button
            onClick={handleBackToHome}
            className="rounded-md px-4 py-2 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            style={{ backgroundColor: primaryColor }}
          >
            {t("ThankYouPage.button")}
          </button>
        </div>
      </div>
      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default ThankYouPage;
