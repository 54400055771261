import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../config";

const Newsletter = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(""); // Default to empty string
  const [phone, setPhone] = useState(""); // Default to empty string
  const [format, setFormat] = useState(""); // Default to empty string

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      setEmail(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "format") {
      setFormat(value);
    }
  };

  const primaryColor = config.env.primaryColor || "#ED6D2D";

  const gradientStyle = {
    background: `linear-gradient(to top left, #000000 0%, #000000 50%, ${primaryColor} 100%)`,
    boxShadow: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`
  };

  return (
    <div className="w-full py-16 text-white px-4" style={gradientStyle}>
         <div className="max-w-[1240px] mx-auto grid lg:grid-cols-4">
        <div className="lg:col-span-3 my-2">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {t("CTA.main")}
          </h1>
          <p>{t("CTA.subtitle")}</p>
        </div>
        <div className="my-4">
          <div className="flex flex-col items-center justify-between w-full">
          <input
              type="email"
              id="email"
              name="email"
              value={email}
              placeholder="Email"
              onChange={handleInputChange}
              className="text-black mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />

            {/* Format Selection */}
            <select
                id="format"
                name="format"
                value={format}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="1">{t("MainForm.residentialInstallation")}</option>
                <option value="2">{t("MainForm.professionalInstallation")}</option>
              </select>
          </div>
        </div>
      </div>
      <div className="max-w-[1240px] mx-auto">
        <Link
          to={`/booking?email=${email}&format=${format}`}
        >
          <button
            className="w-full rounded-md font-medium my-6 mx-auto py-3 text-black enabled:hover:bg-indigo-400 disabled:text-slate-500" style={{ backgroundColor: primaryColor }}
          >
            {t("Button.cta")}
          </button>
        </Link>
        <p>
          {t("CTA.note")}{" "}
          <Link to="/privacy">
            <span className="" style={{ color: primaryColor }}>{t("CTA.link")}.</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Newsletter;
