import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";
import { FaPhone } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa"; // Import the spinner icon

const BookRideForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const primaryColor = config.env.primaryColor || "#ED6D2D";
  const phoneNumber = config.env.phone || "to define"; // Fallback number if not in config

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    installationType: "",
    installationTypes: [],
    message: ""
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    const format = searchParams.get("format");

    if (email || format) {
      setFormData(prevData => ({
        ...prevData,
        email: email || "",
        installationType: format === "1" ? t("MainForm.residentialInstallation") :
                          format === "2" ? t("MainForm.professionalInstallation") :
                          prevData.installationType
      }));
    }
  }, [location, t]);

  const installationTypeOptions = [
    "MainForm.ventilationSystem",
    "MainForm.heatPump",
    "MainForm.coldRoom",
    "MainForm.airConditioning"
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        installationTypes: checked
          ? [...prevData.installationTypes, value]
          : prevData.installationTypes.filter((item) => item !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.firstname) errors.firstname = t("MainForm.errorFirstname");
    if (!data.lastname) errors.lastname = t("MainForm.errorLastname");
    if (!data.email) errors.email = t("MainForm.errorEmail");
    if (!data.phone) errors.phone = t("MainForm.errorPhone");
    if (!data.installationType) errors.installationType = t("MainForm.errorInstallationType");
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const errors = validateForm(formData);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        // Construct the message
        const message = `
          ${t("MainForm.firstname")}: ${formData.firstname}
          ${t("MainForm.lastname")}: ${formData.lastname}
          ${t("MainForm.email")}: ${formData.email}
          ${t("MainForm.phone")}: ${formData.phone}
          ${t("MainForm.installationType")}: ${formData.installationType}
          ${t("MainForm.installationTypes")}: ${formData.installationTypes.map(type => t(type)).join(", ")}
          ${t("MainForm.message")}: ${formData.message}
        `;

        console.log("message", message)

        // Send email using EmailJS
        await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          {
            to_name: `${formData.firstname} ${formData.lastname}`,
            to_email: formData.email,
            to_bcc: process.env.REACT_APP_EMAILJS_BCC,
            message: message,
          },
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        );

        toast.success(t("toast.success"));
        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          installationType: "",
          installationTypes: [],
          message: ""
        });

        // Redirect to thank you page after successful submission
        setTimeout(() => {
          navigate("/thankyou");
        }, 3000);
      } catch (error) {
        console.error(error);
        toast.error(t("toast.failure"));
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div className="max-w-[800px] mx-auto py-16 px-4">
      <h2 className="text-3xl font-bold text-white text-center mb-8">
        {t("MainForm.title")}
      </h2>

      <div className="bg-gradient-to-br from-black via-gray-900 to-black rounded-lg p-6 mb-8">
        <h3 className="text-2xl font-semibold text-white mb-4">
          {t("MainForm.emergencyTitle")}
        </h3>
        <p className="lg:text-xl text-white mb-4">
          {t("MainForm.emergencyDescription")}
        </p>
        <a href={`tel:${phoneNumber}`} className="inline-block">
          <button className="w-full sm:w-auto text-center rounded-full font-medium px-6 py-3 bg-red-600 hover:bg-red-700 text-white transition duration-300 flex items-center justify-center">
            <FaPhone className="mr-2" />
            {t("MainForm.callNow")} - {phoneNumber}
          </button>
        </a>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label htmlFor="firstname" className="block text-sm font-medium text-white">
              {t("MainForm.firstname")}
            </label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              className="text-black  mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            {formErrors.firstname && <p className="text-red-500 text-xs mt-1">{formErrors.firstname}</p>}
          </div>

          <div>
            <label htmlFor="lastname" className="block text-sm font-medium text-white">
              {t("MainForm.lastname")}
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              className="text-black mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            {formErrors.lastname && <p className="text-red-500 text-xs mt-1">{formErrors.lastname}</p>}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-white">
              {t("MainForm.email")}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="text-black mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            {formErrors.email && <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>}
          </div>

          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-white">
              {t("MainForm.phone")}
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="text-black mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            {formErrors.phone && <p className="text-red-500 text-xs mt-1">{formErrors.phone}</p>}
          </div>
        </div>

        <div className="pt-10">
          <label className="block lg:text-xl text-sm font-medium text-white mb-2">
            {t("MainForm.installationType")}
          </label>
          <div className="space-y-2">
            <label className="inline-flex items-center px-2 py-1">
              <input
                type="radio"
                name="installationType"
                value={t("MainForm.professionalInstallation")}
                checked={formData.installationType === t("MainForm.professionalInstallation")}
                onChange={handleChange}
                className="form-radio"
              />
              <span className="ml-2 text-white">{t("MainForm.professionalInstallation")}</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="installationType"
                value={t("MainForm.residentialInstallation")}
                checked={formData.installationType === t("MainForm.residentialInstallation")}
                onChange={handleChange}
                className="form-radio"
              />
              <span className="ml-2 text-white">{t("MainForm.residentialInstallation")}</span>
            </label>
          </div>
          {formErrors.installationType && <p className="text-red-500 text-xs mt-1">{formErrors.installationType}</p>}
        </div>

        <div className="pt-10">
          <label className="block lg:text-xl text-sm font-medium text-white mb-2">
            {t("MainForm.installationTypes")}
          </label>
          <div className="space-y-2">
            {installationTypeOptions.map((type) => (
              <label key={type} className="inline-flex items-center px-2 py-1">
                <input
                  type="checkbox"
                  name="installationTypes"
                  value={type}
                  checked={formData.installationTypes.includes(type)}
                  onChange={handleChange}
                  className="form-checkbox"
                />
                <span className="ml-2 text-white">{t(type)}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="pt-10">
          <label htmlFor="message" className="block lg:text-xl text-sm font-medium">
            {t("MainForm.message")}
          </label>
          <textarea
            id="message"
            name="message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            className="text-black mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          ></textarea>
        </div>

        <div>
          <button
            type="submit"
            className="w-full rounded-md px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 flex items-center justify-center"
            style={{ backgroundColor: primaryColor }}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <FaSpinner className="animate-spin mr-2" />
                {t("MainForm.submitting")}
              </>
            ) : (
              t("MainForm.submit")
            )}
          </button>
        </div>
      </form>

      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default BookRideForm;
