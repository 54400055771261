import React, { useContext, useEffect } from "react";
import TableBookings from "../components/Admin/TableBookings";
import UserContext from "../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import config from "../config";
const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const primaryColor = config.env.primaryColor || "#ED6D2D";

  const successToast = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  useEffect(() => {
    if (location.state?.message) {
      successToast(location.state.message);
    }
  }, [location]);

  let username;
  if (user.username) {
    username = user.username.charAt(0).toUpperCase() + user.username.slice(1);
  } else {
    username = "R2D2";
  }

  return (
    <div className="w-full py-16 px-4">
      <div className="max-w-[1240px] mx-auto">
        <div className="bg-gray-800 pb-32">
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight" style={{ color: primaryColor }}>
                {t("DashboardPage.for")} {user && username}
              </h1>
            </div>
          </header>
        </div>

        <div className="bg-gray-800 -mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-black px-5 py-6 shadow sm:px-6">
              <TableBookings />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default Dashboard;
