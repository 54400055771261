import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaPhoneAlt, FaFileAlt, FaUserCog } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import config from '../config';
import { FaPhone } from 'react-icons/fa';

const StepCard = ({ icon, title, description }) => {
    const primaryColor = config.env.primaryColor || "#ED6D2D";
    return (
        <div className="bg-gray-100 rounded-lg shadow-md p-6 flex flex-col items-center text-center">
            <div className="lg:text-2xl text-4xl mb-4" style={{ color: primaryColor }}>{icon}</div>
            <h3 className="lg:text-2xl text-xl font-semibold mb-2">{title}</h3>
            <p className="lg:text-2xl text-gray-600">{description}</p>
        </div>
    );
};

const HowItWorks = () => {
  const { t } = useTranslation();
  const primaryColor = config.env.primaryColor || "#ED6D2D";
  const phoneNumber = config.env.phone || "to define"; // Fallback number if not in config
  const ASSETS_DIR = config.env.img_dir || "assets";

  const imagePath = require(`../${ASSETS_DIR}/how.png`);

  const steps = [
    {
      icon: <FaPhoneAlt />,
      title: t('HowItWorks.step1Title'),
      description: t('HowItWorks.step1Desc')
    },
    {
      icon: <FaFileAlt />,
      title: t('HowItWorks.step2Title'),
      description: t('HowItWorks.step2Desc')
    },
    {
      icon: <FaUserCog />,
      title: t('HowItWorks.step3Title'),
      description: t('HowItWorks.step3Desc')
    }
  ];

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-gray-900 text-center mb-12">
          {t('HowItWorks.title')}
        </h1>
        <div className="flex flex-col md:flex-row items-center mb-16">
          <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0">
            
            <img 
              src={imagePath}
              alt="How It Works" 
              className="rounded-lg shadow-md w-full h-auto object-cover"
            />
          </div>
          <div className="md:w-1/2">
          <p className="lg:text-2xl text-xl text-gray-600">
              {t('HowItWorks.description')}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <StepCard key={index} {...step} />
          ))}
        </div>
        <div className='mt-12 flex flex-col sm:flex-row justify-center items-center gap-4'>
          <Link to="/booking">
            <button className={`w-[265px] text-center rounded-full font-medium px-5 py-3 bg-black hover:bg-gray-700`} style={{ color: primaryColor }}>
              {t('Hero.button_1')}
            </button>
          </Link>
          <a href={`tel:${phoneNumber}`}>
            <button className={`w-[265px] text-center rounded-full font-medium px-5 py-3 bg-black hover:bg-gray-700`} style={{ color: primaryColor }}>
              {phoneNumber}<span className='inline-flex ml-2'><FaPhone size={16} /></span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
