import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Hero from '../components/Hero';
import AboutUs from '../components/AboutUs';
import HowItWorks from '../components/HowItWorks';
import HowTo from '../components/HowTo';
import Services from '../components/Services';
import CTA from '../components/CTA';
import FAQlist from '../components/FAQ/FAQlist';

const Home = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.scrollTo) {
            if (location.state.scrollTo === 'top') {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            } else {
                const section = document.getElementById(location.state.scrollTo);
                if (section) {
                    setTimeout(() => {
                        section.scrollIntoView({ behavior: 'smooth' });
                    }, 100);
                }
            }
        }
    }, [location]);

    return (
        <div>
            <Hero />
            <div id="about">
                <AboutUs />
            </div>
            <HowTo />
            <Services />
            <HowItWorks />
            <CTA />
            <div id="faq">
                <FAQlist />
            </div>
        </div>
    )
}

export default Home
