//import themes from "daisyui/src/theming/themes";

import BookRideForm from "./components/Form/BookRideForm";
import { privacyDataItems } from "./privacy_data";  // Import the privacy data
import { termsData } from "./terms";  // Import the privacy data



const configs = {
  limitlesstaxi: {
    // REQUIRED
    appName: "Energy Mastering",
    // REQUIRED: a short description of your app for SEO tags (can be overwritten)
    appDescription:
      "Expert en installations, maintenance et réparation pour le résidentiel, le commercial pour des systèmes de climatisation et ventilation",
    // REQUIRED (no https://, not trialing slash at the end, just the naked domain)
    appKeyWords:
      "climatisation, ventilation, installation climatisation, maintenance climatisation, réparation climatisation, résidentiel, commercial, systèmes de climatisation, systèmes de ventilation, expert climatisation, maintenance HVAC, service ventilation, expert climatisation Bruxelles, climatisation industrielle, entretien climatisation, dépannage climatisation",
    appTitle:
      "Expert en installations, maintenance et réparation pour le résidentiel, le commercial pour des systèmes de climatisation et ventilation",
    appFavicon: "favicon.ico",
    appLogo: "em_logo.png",
    googleTag: false,
    googleTagId: "AW-XXXX",

    domainName: "energy-mastering.com",
    pricing_component: BookRideForm,
    privacyDataItems,
    termsData,
    env: {
      phone: "+32 493 35 88 40",
      email: "info@energy-mastering.com",
      locales_dir: "locales",
      img_dir: "assets",
      primaryColor: "#ED6D2D",
    },
    aws: {
      // If you use AWS S3/Cloudfront, put values in here
      bucket: "bucket-name",
      bucketUrl: `https://bucket-name.s3.amazonaws.com/`,
      cdn: "https://cdn-id.cloudfront.net/",
    },
    mailgun: {
      // subdomain to use when sending emails, if you don't have a subdomain, just remove it. Highly recommended to have one (i.e. mg.yourdomain.com or mail.yourdomain.com)
      subdomain: "mg",
      // REQUIRED — Email 'From' field to be used when sending magic login links
      fromNoReply: `Energy Mastering <noreply@energy-mastering.com>`,
      // REQUIRED — Email 'From' field to be used when sending other emails, like abandoned carts, updates etc..
      fromAdmin: `Joris de Energy Mastering <joris@energy-mastering.com>`,
      // Email shown to customer if need support. Leave empty if not needed => if empty, set up Crisp above, otherwise you won't be able to offer customer support."
      supportEmail: "joris@energy-mastering.com",
      // When someone replies to supportEmail sent by the app, forward it to the email below (otherwise it's lost). If you set supportEmail to empty, this will be ignored.
      forwardRepliesTo: "jorisck@gmail.com",
    },
    colors: {
      // REQUIRED — The DaisyUI theme to use (added to the main layout.js). Leave blank for default (light & dark mode). If you any other theme than light/dark, you need to add it in config.tailwind.js in daisyui.themes.
      theme: "light",
      // REQUIRED — This color will be reflected on the whole app outside of the document (loading bar, Chrome tabs, etc..). By default it takes the primary color from your DaisyUI theme (make sure to update your the theme name after "data-theme=")
      // OR you can just do this to use a custom color: main: "#f37055". HEX only.
      //main: themes["light"]["primary"],
    }
  },

};

const getCompanyConfig = () => {
  const company = process.env.REACT_APP_CONFIG_FILE || "limitlesstaxi";
  console.log("company", company);
  return configs[company] || configs.limitlesstaxi;
};

export default getCompanyConfig();
