import React from 'react'
import { BiSolidTree } from "react-icons/bi";
import { FaPhone } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import config from "../config";

const Hero = () => {
    const { t } = useTranslation();

    const primaryColor = config.env.primaryColor || "#ED6D2D";
    const phoneNumber = config.env.phone || "to define"; // Fallback number if not in config

    const gradientStyle = {
        background: `linear-gradient(135deg, #000000 0%, #000000 50%, ${primaryColor} 100%)`,
        boxShadow: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`
    };

    return (
        <div id="home" className="text-white" style={gradientStyle}>
            <div className='max-w-[900px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                <p className="font-bold p-2 " style={{ color: primaryColor }}>{t('Hero.uptitle')}</p>
                <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 '> {t('Hero.main_1')}</h1>
                <p className='md:text-2xl text-xl font-bold text-gray-300 pt-4'>{t('Hero.subtitle')}</p>
                <div className='mt-3 flex flex-col sm:flex-row justify-center items-center gap-4'>
                    <Link to="/booking">
                        <button className="w-[265px] text-center rounded-full font-medium px-5 py-3 text-black hover:bg-indigo-700" style={{ backgroundColor: primaryColor }}>
                            {t('Hero.button_1')}
                        </button>
                    </Link>
                    <a href={`tel:${phoneNumber}`}>
                        <button className="w-[265px] text-center rounded-full font-medium px-5 py-3 bg-gradient-to-r from-red-600 to-orange-500 hover:from-red-700 hover:to-orange-600 text-white transition duration-300">
                            {phoneNumber}<span className='inline-flex ml-2'><FaPhone size={16} /></span>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Hero
