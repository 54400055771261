import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import config from "../config";

const Booking = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const participantsType = searchParams.get("participantsType") || ""; // Default to empty if not provided
  const numParticipants = searchParams.get("numParticipants") || ""; // Default to an empty string if not provided
  const format = searchParams.get("format") || "Stay"; // Default to an empty string if not provided

  const primaryColor = config.env.primaryColor || "#ED6D2D";

  const gradientStyle = {
    background: `linear-gradient(to bottom right, #000000 0%, #000000 50%, ${primaryColor} 100%)`,
    boxShadow: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`
  };
  
  return (
    <div className="w-full py-16 text-white px-4" style={gradientStyle}>
      <config.pricing_component
        participantsType={participantsType}
        numParticipants={numParticipants}
        format={format}
      />
    </div>
  );
};

export default Booking;
